'use client';

import { I18nextProvider } from 'react-i18next';
import { ReactNode } from 'react';
import initTranslations from '@/app/i18n';
import { Resource, createInstance } from 'i18next';
import i18nConfig from '@/i18nConfig';

export const LanguageAIItem = "language-first"

export default function TranslationsProvider({
  children,
  locale,
  namespaces,
  resources
}: {
  children: ReactNode;
  locale: string;
  namespaces: string[];
  resources: Resource;
}) {



  if (typeof window !== 'undefined') {

    // 策略 如果本地 标记的存储为空，获取系统语言
    const settingLanguage = localStorage.getItem(LanguageAIItem);

    console.log('---TranslationsProvider---', locale, !i18nConfig.locales.includes(locale), namespaces, resources, settingLanguage)

    if (settingLanguage == null || !settingLanguage) {

      if (typeof navigator !== 'undefined') {
        if (navigator.languages && navigator.languages.length > 0) { // chrome only; not an array, so can't use .push.apply instead of iterating
          console.log('---settingLanguage---', settingLanguage, navigator.languages)
          let firstLang = navigator.languages[0]

          if (!i18nConfig.locales.includes(firstLang)) {
            firstLang = i18nConfig.defaultLocale
          }
          localStorage.setItem(LanguageAIItem, firstLang)

          // locale = firstLang
          if (locale != firstLang) {
            window.location.href = `/${firstLang}`
            // window.location.href = `/`
          }
        }

      }
    } else if (!i18nConfig.locales.includes(settingLanguage)) {

      localStorage.setItem(LanguageAIItem, i18nConfig.defaultLocale)
      window.location.href = `/${i18nConfig.defaultLocale}`
      // window.location.href = `/`

    }
  }

  const i18n = createInstance();

  // 如果 locale 是空或未定义，只能根据浏览器的语言设置 locale，否则用户连接ai staff时就永远是英语了
  // 要检查是否在客户端环境中
  if (!locale && typeof window !== 'undefined') {
    const browserLanguage = navigator.language; // 只在客户端环境中使用 navigator
    locale = browserLanguage.startsWith('zh') ? 'zh-CN' : 'en';
  }


  initTranslations(locale, namespaces, i18n, resources);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}
