"use client"; // 这个指令告诉 Next.js 这个组件是在客户端渲染的

import React from 'react';

export default function AistaffButton() {
    const handleClick = () => {
        window.open(
            "https://www.jellydropsllc.com/staffai/60?serial_no=C7mXjw7xUF3VxUBV_EVzy1GEw",
            "_blank" // 这个参数指定在新窗口中打开
        );
    };

    return (
        <button className="aistaff-button" onClick={handleClick}>
            <img src="/ai_bot.gif" alt="AI Staff" />
        </button>
    );
}
