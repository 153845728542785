// components/ScrollingBar.tsx

"use client"; // 将此组件标记为 Client Component

import { usePathname } from 'next/navigation';

export default function ScrollingBar() {
    const pathname = usePathname().replace(/\/$/, ''); // 去掉末尾的斜杠
    // 输出当前路径到控制台
    //console.log('........................Current pathname:', pathname);

    // 判断当前路径是否为 /staff 或 /cstaff
    const isRootPage = pathname === "/staff" || pathname === "/about" || pathname === "/zh-CN/cabout";

    // 输出判断结果到控制台
    //console.log('........................Is root page:', isRootPage);

    if (!isRootPage) return null;

    return (
        <div className="scrolling-bar-container">
            <div className="scrolling-bar">
                We are currently a fully self-funded team, and we welcome anyone with resources that can help this SaaS platform gain market presence and grow with us.
                <a href="mailto:hailin.zhao@jellydropsllc.com">Click here to contact us</a>
            </div>
        </div>
    );
}